@import 'styles/vars.css';

.addressFillContainer {
	max-width: var(--max-width);
	min-width: var(--min-width);
	position: relative;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.addressFillStandardWidth {
	width: 95%;
}

.addressFillFullWidth {
	width: 100%;
}

.addressFillForm {
	position: relative;
	width: 100%;
	min-width: var(--min-width);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
