@import 'styles/vars.css';

/* IndicatorCount */

.indicatorCountRoot {
	background-color: var(--color-error);
	height: 20px;
	width: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.indicatorCountNumber {
	display: inline-block !important;
	color: white !important;
	font-size: 10px !important;
	font-weight: bold !important;
}
