@import 'styles/vars.css';

/* EmailStaticTextRow */

.emailStaticResendContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.emailStaticResendButton {
	cursor: pointer;
	color: var(--color-main);
}

/* EmailTextFieldRow */

.emailInputResendButton {
	cursor: pointer;
	color: var(--color-main);
}

/* FormSectionHeader */

.formSectionHeader {
	height: 72px;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	margin: 8px 0 8px 0;
	padding: 24px 0 8px 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 14px;
	font-weight: bold;
	position: relative;
}

.top {
	border-top: 1px solid var(--color-divider);
}

.bottom {
	border-bottom: 1px solid var(--color-divider);
}

/* FormToggleSwitch */

.formToggleContainer {
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: var(--max-width);
	min-width: var(--min-width);
	margin: 0 auto;
}

.formToggleLabel {
	height: 100%;
	display: flex;
	align-items: center;
}
