@import 'styles/vars.css';

.homesContainer {
	flex: 1;
	height: 100%;
	display: flex;
	position: relative;
}

.homesContent {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	background-color: white;
}

.manageHomeRoot {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}
