@import 'styles/vars.css';

.editPostContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: white;
	padding: 16px 0;
}

.addPostForm {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	height: 55%;
	padding-top: 8px;
}

.addPostContent {
	width: 95%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	padding-bottom: 16px;
}

/* ChangeRequestEdit */

.changeRequestMessageRoot {
	margin: 30px 4px 12px;
	border: 1px solid var(--color-error);
	border-radius: 5px;
	padding: 24px 12px 12px;
	max-width: var(--max-width);
	min-width: var(--min-width);
	position: relative;
}

.changeRequestMessageTitle {
	position: absolute;
	top: -10px;
	background-color: var(--color-error);
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 5px;
}

.changeRequestMessageSeeMore {
	color: var(--color-main);
	cursor: pointer;
}

/* EditPostBasicInfo */

.addPostInputField {
	position: relative;
	width: 100%;
	margin: 8px 0 !important;
}

.input-body {
	height: calc(100% - 180px);
}

/* EditPostButtonContainer */

.editPostFooterRoot {
	width: 100%;
	height: 100%;
	z-index: 2000;
	bottom: 0;
	background-color: var(--color-gray);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid var(--color-divider);
	box-shadow: 0px -3px 10px var(--color-divider);
}

.editPostFooterContent {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

@media only screen and (max-width: 660px) {
	.editPostFooterContent {
		width: 100%;
	}
}

/* EditPostServiceSelector */

.editPostServiceSelectorRoot {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 16px 0 8px;
	padding-bottom: 8px;
	box-shadow: 0px 8px 0px var(--color-gray);
}

.editPostServiceSelectorContent {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

/* ServiceOptions */

.serviceOptionsTile {
	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 150px;
	margin: 8px;
	cursor: pointer;
	background-color: white;
	flex: 1;
}

.serviceOptionsTile:hover {
	opacity: 0.8;
}

.tileMin {
	flex: unset;
	width: 80px;
	height: 120px;
	margin: 4px;
}

.iconMin {
	width: 30px;
	height: 30px;
	margin: 10px;
}

.serviceOptionsText {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.activeText {
	font-weight: bold;
}

.serviceOptionsTileContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.serviceOptionsTileContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

/* ServiceAvatar */

.serviceOptionsAvatar {
	width: 80px !important;
	height: 80px !important;
	background-color: var(--color-background-active) !important;
	margin: 10px 20px !important;
	z-index: 200 !important;
}

.minAvatar {
	width: 60px !important;
	height: 60px !important;
}

.serviceOptionsActiveIcon {
	background-color: var(--color-main) !important;
}

/* SuggestedPost */

.categorySelectDivider {
	height: 2px !important;
}

.categorySelectMenuItem {
	display: flex !important;
	justify-content: space-between !important;
}

.select {
	width: 100%;
	height: 53px;
	font-weight: bold;
	color: red;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 100%;
}

.inputProps {
	color: black !important;
	height: 24px !important;
	display: flex !important;
	align-items: center !important;
	font-weight: normal !important;
}

.parentMenuItem {
	font-weight: bold;
}

.formContainer {
	margin-top: 5px;
	margin-bottom: 5px;
}

/* SwitcherRadios */

.switcherRadiosRoot {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	width: 100% !important;
}

.switcherRadiosLabel {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	font-size: 16px !important;
}

.switcherRadiosHelpIcon {
	height: 16px !important;
	width: 16px !important;
	color: var(--color-main) !important;
}

.switcherRadiosGroup {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	margin-left: 24px !important;
}

/* CategorySelect */

.select {
	width: 100%;
	height: 53px;
	font-weight: bold;
	color: red;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 100%;
}

.inputProps {
	color: var(--color-light);
	height: 24px;
	display: flex;
	align-items: center;
}

.categorySelectMenuItem {
	display: flex !important;
	align-items: center !important;
}

.categorySelectDivider {
	height: 2px;
}

.parentMenuItem {
	font-weight: bold;
}
