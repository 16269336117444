@import 'styles/vars.css';

/* Across Components */

.casaUserCenteredFlex {
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.casaUserWidth {
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 100%;
}

.casaBoldLabel {
	font-weight: bold;
}

.casaButtonWrap {
	white-space: nowrap;
	padding: 0;
}

.casaWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	overflow-y: hidden;
	background-color: white;
}

/* CasaExtendedPermissions */

.ePermissionsRoot {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: white;
	padding-bottom: 10px;
	margin-bottom: 8px;
	border-bottom: 1px solid #e8ecee;
}

.ePermissionsContainer {
	position: relative;
	width: 100%;
	margin: 8px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* RemoveCasaUserButton */

.casaUserButton {
	position: relative;
	width: 100%;
	margin: 8px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--color-error);
}

/* CasaConections */

.casaConnectionsContainer {
	position: relative;
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	background-color: var(--main);
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* CasaUsers */

.casaUserInvite {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 600;
	background-color: white;
}

.casaUserContent {
	position: relative;
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	background-color: var(--main);
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* ManageCasa */

.casaAddressContainer {
	max-width: var(--max-width);
	min-width: var(--min-width);
	position: relative;
	background-color: var(--main);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.casaAddressForm {
	position: relative;
	width: 100%;
	max-width: var(--max-width);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
