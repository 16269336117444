@import 'styles/vars.css';

/* DashboardNav */

.dashboardNavContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	max-height: calc(100vh - 180px);
	overflow: auto;
	border-top: 1px solid var(--color-main);
	padding: 14px 20px 0 10px;
	margin-left: 0px;
	-webkit-overflow-scrolling: touch;
	padding-bottom: 65px;
}

@media only screen and (max-width: 660px) {
	.dashboardNavContainer {
		position: relative;
		width: 100%;
		height: 100%;
		max-height: calc(100vh - 312px);
	}
}

/* DashboardNavItem */

.dashboardNavItemRoot {
	position: relative;
	width: 100%;
	padding: 0 14px 0 16px;
	color: white;
}

.dashboardNavItemActive {
	background: var(--color-background-active);
	border-radius: 24px;
	color: black;
}

.dashboardNavItemContainer {
	width: 100%;
	height: 48px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
}

.dashboardIconContainer {
	margin: 0 4% 0 0;
	position: relative;
}

.dashboardNavItemIcon {
	color: white;
}

.dashboardNavItemName {
	display: flex;
	align-items: center;
	font-size: 14px;
	text-align: left;
	margin-left: 5px;
	width: 50%;
}

.boldWeight {
	display: flex;
	align-items: center;
	font-size: 14px;
	text-align: left;
	margin-left: 15px;
	width: 50%;
	font-weight: 900;
}

.normalWeight {
	display: flex;
	align-items: center;
	font-size: 14px;
	text-align: left;
	margin-left: 30px;
	width: 50%;
	font-weight: 400;
}

.dashboardNavItemActiveDot {
	position: absolute;
	right: 32px;
	width: 8px;
	height: 36px;
	display: flex;
	align-items: center;
}

.dashboardNavItemDot {
	background-color: var(--color-main);
	height: 8px;
	width: 8px;
	border-radius: 50%;
}

@media only screen and (max-width: 660px) {
	.dashboardNavItemRoot {
		flex-direction: column-reverse;
	}
	.dashboardNavItemContainer {
		height: 60px;
		padding: 5px 5px;
	}
	.dashboardNavItemName {
		font-weight: bolder;
		margin-left: 32px;
	}
	.boldWeight {
		font-weight: bolder;
		margin-left: 20px;
	}
	.normalWeight {
		font-weight: bolder;
		margin-left: 35px;
	}
}

/* DashboardNavParent */

.dashboardNavParentRoot {
	position: relative;
	width: 100%;
	padding: 0 14px 0 16px;
	color: white;
}

.dashboardNavParentContainer {
	width: 100%;
	height: 48px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
}

@media only screen and (max-width: 660px) {
	.dashboardNavParentRoot {
		flex-direction: 'column-reverse';
	}

	.dashboardNavParentContainer {
		height: 60px;
		padding: 5px 5px;
	}
}

/* DashboardUser */

.dashboardUserRoot {
	position: relative;
	display: flex;
	align-items: flex-start;
	width: 100%;
	color: white;
	justify-content: center;
	flex-direction: column;
	padding: 28px 24px;
	background-color: #222222;
}

.dashboardUserName {
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	margin-left: 10px;
	text-align: left;
}

.dashboardUser {
	width: 75px;
	height: 75px;
	font-size: 30px;
	background-color: #00a0e8;
}

.dashboardUserToggle {
	color: red;
	flex: 1;
	border-right: unset;
	position: absolute;
	top: 12px;
	right: 12px;
}

.dashboardUserContainer {
	margin-top: 8px;
}

/* OnboardBanner */

.onboardBannerRoot {
	position: fixed;
	z-index: 1000;
	bottom: 0;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.onboardBannerContent {
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-color: rgba(255, 50, 50, 0.8);
	color: white;
	padding: 4px 16px;
	border-radius: 5px;
	margin-bottom: 4px;
}

/* Dashboard */

.dashboardContainer {
	position: relative;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 240px;
	flex: 1;
	height: 100vh;
	background-color: var(--color-background-nav);
	z-index: 101;
	transition: all 500ms;
}

.dashboardHide {
	left: -100vw !important;
}

.dashboardCollapse {
	max-width: 0;
}

.dashboardNav {
	width: 100%;
}

.dashboardLogoImage {
	width: 175px;
}

.dashboardLogo {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0px;
	width: 100%;
	margin-right: 10%;
}

@media only screen and (max-width: 960px) {
	.dashboardContainer {
		position: fixed;
		min-width: 249px;
		z-index: 200;
	}
}

/* DashboardWrapper */

.appContent {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.routeContainer {
	position: relative;
	right: 0;
	display: flex;
	justify-content: center;
	height: 100%;
	flex: 1;
	max-width: 100vw;
	overflow: hidden;
	z-index: 150;
	transition: max-width 500ms;
}

/* IndicatorDot */

.indicatorDotRoot {
	height: 8px;
	width: 8px;
	border-radius: 50%;
}
