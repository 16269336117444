@import 'styles/vars.css';

/* UserList */

.userListContainer {
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: white;
}

.userListTileMap {
	flex: 1;
	overflow: auto;
	margin-top: 4px;
}

/* HomeTile */

.headerText {
	display: flex;
	width: 80%;
	font-size: 16px;
	font-weight: 900;
	border-bottom: 2px solid var(--color-light);
	justify-content: center;
	align-items: center;
	padding-bottom: 5px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.outerDiv {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* ListLabel */

.root {
	box-sizing: border-box;
	width: 100%;
	height: 59px;
	padding-left: 15px;
	position: relative;
	bottom: 10;
	display: flex;
	align-items: flex-end;
	font-size: 24px;
}

.rootInvite {
	box-sizing: border-box;
	width: 100%;
	height: 59px;
	padding-left: 15px;
	position: relative;
	bottom: 10;
	padding-right: 10px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	font-size: 24px;
}

.listLabelDashToggle {
	margin-left: -16px;
}

/* ListSort */

.listSortContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	height: auto;
	z-index: 100;
	border-bottom: 1px solid var(--color-divider);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.04),
		0px 1px 2px rgba(0, 0, 0, 0.04);
}

.listSort {
	box-sizing: border-box;
	position: relative;
	top: 0;
	background-color: white;
	height: 59px;
	width: 100%;
	display: flex;
	align-items: center;
	border-top: 1px solid var(--color-divider);
}

.listSortBy {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 44px;
	text-align: right;
}

.listSortOptionIcon {
	margin: 0 4px;
	color: #aaaaaa;
}

.listSortOptionLabel {
	font-family: var(--font-family-default);
	font-weight: 500;
	font-size: 16px;
	line-height: 12px;
	text-transform: uppercase;
	color: #aaaaaa;
	margin-left: 4px;
}

/* SlideContainer */

.slideContainerRoot {
	position: relative;
	height: 100%;
	width: 350px;
	min-width: 350px;
	z-index: 100;
	box-sizing: border-box;
	border-right: 1px solid #f4f4f4;
	border-left: 1px solid var(--color-divider);
}

@media only screen and (max-width: 1280px) {
	.slideContainerRoot {
		min-width: 250px;
		width: 250px;
	}
}

@media only screen and (max-width: 960px) {
	.slideContainerRoot {
		z-index: 800;
		width: 100vw;
		max-width: 100vw;
		position: absolute;
	}
}

/* UserListEmpty */

.userListEmptyRoot {
	height: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* UserListSearch */

.userListSearchRoot {
	height: 60px;
	margin-top: 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 12px;
}

/* UserListTile */

.selectListTileActive {
	font-weight: 700;
	background-color: var(--color-background-active) !important;
}

.selectListTile {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	display: flex;
	align-items: center;
	background-color: white;
	padding-left: 5%;
}

.selectListTileSelectable:hover {
	background-color: var(--color-divider) !important;
	cursor: pointer;
}

.selectListTileNoAvatar {
	margin-left: 16px;
}

.selectListTileName {
	display: flex;
	align-items: center;
	position: relative;
	flex: 1;
	padding-left: 2%;
}

.selectListTileNameInactive {
	display: flex;
	align-items: center;
	position: relative;
	flex: 1;
	padding-left: 2%;
	color: gray;
}

.selectListTileIndicator {
	position: absolute;
	right: 10px;
}
