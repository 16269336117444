@import 'styles/vars.css';

/* SendInvite */

.container {
	width: 100%;
	height: 100%;
	align-items: center;
	flex-direction: column;
	background-color: white;
}

.content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow-y: auto;
	& > div {
		width: 95%;
		max-width: var(--max-width);
		min-width: var(--min-width);
	}
}

/* InviteAssignment */

.label {
	height: 32px;
	width: 100%;
	font-weight: bold;
	font-size: 14px;
	line-height: 32px;
	margin-top: 8px;
	margin-bottom: 8px;
	border-bottom: 1px solid var(--color-disabled);
}

.subheader {
	font-weight: 500;
	border-top: 1px solid var(--color-disabled);
}

.field {
	width: 100%;
}

/* InviteForm */

.inputLabel {
	background-color: white;
	padding-left: 5px;
	padding-right: 5px;
}

.inviteFormIcon {
	cursor: pointer;
}

.inviteFormContainer {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

/* InviteNoBranch */

.noBranchContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.noBranchRoot {
	height: 100px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.noBranchTitle {
	text-align: center;
	margin: 16px 8px;
	font-size: 16px;
}

.noBranchButtonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 660px) {
	.noBranchButtonContainer {
		flex-direction: column-reverse;
		& > {
			margin: 4px 0;
		}
	}
}
