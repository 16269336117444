.archiveContainer {
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
}

.archiveFeed {
	position: relative;
	height: calc(100% - 116px);
	width: 100%;
	max-width: 100vw;
	z-index: 0;
}

.archiveContent {
	background-color: #ffffff;
	z-index: 400;
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	width: 100%;
}

.archiveProfile {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;
}
