@import 'styles/vars.css';

/* CasaAdminTile */

.selectListTile {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	display: flex;
	align-items: center;
}

/* CasaUserList */

.selectorListTileList {
	width: 100%;
}

.selectorListActiveTile {
	border-left: 8px solid var(--main);
}

/* ManageCasa */

.manageCasaWidth {
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 100%;
}

.manageCasaCenteredFlex {
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* CasaAgency */

.casaAgencyContainer {
	position: relative;
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	background-color: var(--main);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.casaAgencyFull {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	overflow-y: hidden;
	background-color: white;
}
