/* ExportRequest */

.exportRequest {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	padding-top: 75px;
	overflow-y: scroll;
}

@media only screen and (max-width: 660px) {
	.exportRequest {
		padding: 0;
	}
}

/* ExportContainer */

.exportContainer {
	width: 70%;
	border: solid;
	border-width: 1px;
	display: flex;
	flex-direction: row;
	max-width: 800px;
	min-width: 400px;
	height: 30%;
	padding-top: 1%;
	padding-bottom: 1%;
}

.leftContainer {
	width: 25%;
	height: 100%;
	display: flex;
	justify-content: center;
}

.middleContainer {
	width: 33%;
	height: 100%;
}

.rightContainer {
	width: 33%;
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
}

@media only screen and (max-width: 660px) {
	.exportContainer {
		height: 90%;
		width: 100%;
		min-width: 0;
		flex-direction: column;
		padding-left: 5%;
		padding-right: 5%;
		margin-bottom: 5%;
	}
	.leftContainer {
		width: 100%;
		height: 25%;
	}
	.middleContainer {
		width: 100%;
		height: 25%;
	}
	.rightContainer {
		width: 100%;
		height: 25%;
	}
	.exportRequester {
		flex-direction: row;
		background-color: green;
	}
}

/* ExportRequester */

.requester {
	display: flex;
	flex-direction: column;
}

.headerText {
	font-size: 12px;
}

.text {
	font-size: 14px;
	font-weight: bold;
}

.requesterImage {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 6%;
}

@media only screen and (max-width: 660px) {
	.requester {
		flex-direction: row-reverse;
		align-items: center;
		display: flex;
		margin-right: 10%;
		width: 100%;
	}
	.requesterImage {
		margin-bottom: 0;
		flex: 1;
		display: flex;
		justify-content: flex-start;
		padding-left: 2%;
		padding-right: 5%;
	}
	.requestFrom {
		flex: 3;
		flex-direction: column;
		display: flex;
		align-items: flex-start;
	}
}

/* ExportRequestFor */

.exportRequesterFor {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.requestFor {
	flex: 1;
}

.requestRelation {
	flex: 1;
	margin: 0;
	padding: 0;
}

.requestDate {
	flex: 1;
}

@media only screen and (max-width: 660px) {
	.exportRequesterFor {
		flex-direction: row;
		align-items: center;
	}
}

/* ExportDetails */

.exportDetails {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.detailsTop {
	height: 66%;
}

.reasonText {
	font-size: 12px;
	height: 20%;
	padding-bottom: 2%;
}

.reason {
	overflow-y: auto;
	height: 80%;
}

.detailsBottom {
	height: 30%;
}

@media only screen and (max-width: 660px) {
	.detailsTop {
		margin-bottom: 2%;
	}
	.detailsBottom {
		margin-top: 5%;
	}
}

/* ExportButtons */

.buttonContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.button {
	padding-bottom: 10%;
}

@media only screen and (max-width: 660px) {
	.buttonContainer {
		flex-direction: row;
		height: 100%;
		align-items: flex-end;
		padding-bottom: 3%;
	}
	.button {
		padding: 0;
	}
}
