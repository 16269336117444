@import 'styles/vars.css';

/* LoginForm */

.loginForm {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	margin-bottom: 8px;
}

.loginInput {
	font-size: 15px;
	border-color: black;
	height: 60px;
	width: 100%;
	display: flex;
	padding-bottom: 3px;
	flex-direction: column;
	justify-content: center;
}

.loginInput fieldset {
	box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
}

.loginLink {
	margin-top: 4px;
	cursor: pointer;
	color: var(--color-main);
	font-size: 12px;
}

.loginLink:hover {
	color: #151b26;
}

.loginButtonContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 32px;
	position: relative;
	width: 90%;
	margin: 16px;
}

.loginTermsContainer {
	text-align: center;
}

/* LoginLinks */

.loginFormLinkContainer {
	flex: 1;
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

/* OwnershipCodeEntry */

.ownerCodeRoot {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.ownerCodeContent {
	width: 100%;
}

.ownerCodeTitle {
	font-size: 24px;
	width: 100%;
}

.ownerCodeInfoTag {
	margin-bottom: 8px;
}

.ownerCodeInfo {
	margin: 8px 0 16px 0;
	width: 100%;
}

.ownerCodeInput {
	height: 48px;
	width: 100%;
	font-size: 15px;
	border-radius: 4px;
	margin-top: 8px;
	border-color: black;
}

.ownerCodeWarn {
	color: var(--color-error);
	font-size: 12px;
}

.ownerCodeButtonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	position: relative;
	width: 100%;
	margin-top: 16px;
}

/* PasswordRequest */

.loginRoot {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.loginContent {
	width: 100%;
}

.loginTitle {
	margin-top: 12px;
	font-size: 24px;
	width: 90%;
}

.loginInfo {
	margin: 8px 0 16px 0;
	width: 90%;
}

/* LoginPageRoot */

.loginPageRoot {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row;
	background-color: var(--color-main);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	background-image: url(../../images/login-splash-desktop.jpg);
	box-shadow: inset 0 0 0 50vw rgba(40, 40, 40, 0.65);
}

.loginSectionOne {
	flex: 1;
}

.loginSectionTwo {
	padding: 4px;
	flex: 1.5;
}

.loginWrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	height: 100vh;
	position: relative;
	opacity: 1;
	background-size: cover;
	justify-content: center;
	align-items: center;
}

.loginContainer {
	background-color: #fefefe;
	width: 100%;
	max-width: 400px;
	padding: 32px;
	overflow-y: auto;
	z-index: 10;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin: 16px;
	border-radius: 10px;
}

.loginLogo {
	max-width: 300px;
	width: 100%;
	margin: 40px 16px 32px;
}

@media only screen and (max-width: 450px) {
	.loginPageRoot {
		background-image: unset;
		box-shadow: unset;
		background-color: unset;
	}
}

@media only screen and (max-width: 660px) {
	.loginSectionOne {
		display: none;
	}

	.loginContainer {
		padding: 16px 12px;
	}
}
