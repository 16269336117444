.formSectionContainer {
	margin-top: 16px;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	height: auto;
	color: var(--font-main);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
}

.modalContainer {
	background-color: white;
	height: 250px;
	width: 450px;
	border-radius: 15px;
	padding-left: 1%;
	padding-right: 1%;
	padding-top: 1%;
	display: flex;
	flex-direction: column;
}

.textContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	padding-right: 5%;
	padding-left: 5%;
	font-size: 24px;
}

.lowerTextContainer {
	padding-left: 5%;
	padding-right: 5%;
	margin-bottom: 5%;
}

.inputContainer {
	display: flex;
	flex: 2;
	padding-left: 3%;
	padding-right: 5%;
	padding-top: 2%;
	width: 100%;
	justify-content: center;
}

.buttonContainer {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding-bottom: 3%;
	padding-right: 5%;
	flex: 1;
}

.fullPageContainer {
	background-color: white;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding-top: 10%;
	padding-left: 5%;
	padding-right: 5%;
}

.maxContainer {
	max-width: 800px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.textContainerFull {
	font-size: 24px;
}

.lowerTextContainerFull {
	font-size: 18px;
	padding-bottom: 5%;
	padding-top: 5%;
	text-align: center;
}

.inputContainerFull {
	width: 75%;
}

.buttonContainerFull {
	align-items: center;
	justify-content: center;
	display: flex;
	padding-top: 5%;
}

/* Auth Input */

.authContainer {
	height: 75%;
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	padding-left: 2%;
	padding-right: 2%;
	margin-bottom: 2%;
}

.authInput {
	height: 80%;
	width: 15%;
	margin-left: 2%;
	font-size: 300%;
}
