@import 'styles/vars.css';

/* ChildCode */

.addChildCodeRoot {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: white;
	padding-bottom: 20px;
	margin-bottom: 8px;
}

.addChildCodeContent {
	position: relative;
	width: 95%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: space-between;
}

.addChildCodeButton {
	position: absolute;
	right: 0;
}

.addChildCodeCollapse {
	display: flex;
	flex-direction: column;
}

.addChildVerifyButtonContainer {
	display: flex;
	justify-content: flex-end;
	margin: 8px 0;
}

/* ChildCSW */

.childCswRoot {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.childCswEmailContainer {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
}

.headerText {
	flex: 1;
}

.basicHeader {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
}

/*ChildImage */

.childImageRoot {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
}

.childImageContent {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

/* ChildSchool */

.childSchoolContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: white;
	align-items: center;
}

.childSchoolContent {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: space-between;
}

.childSchoolTextField {
	width: 100%;
	margin: '5px 0';
}

.childSchoolForm {
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 95%;
	display: flex;
	flex-direction: column;
	align-items: left;
}

/* ChildSchoolList */

.schoolListMap {
	display: none;
}
.schoolListMenuItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border-bottom: 1px solid lightgray;
	height: 72px;
}
.schoolName {
	font-weight: bold;
}

/* ChildVaultAccessDate */

.editVaultDateSection {
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 100%;
	height: min-content;
	padding: 8px 0;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
}

.editVaultDatePicker {
	margin-top: 8px;
}

.editVaultDateButtonContainer {
	width: 100%;
	height: 40px;
	margin: 4px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.editVaultDateEditButton {
	cursor: pointer;
	color: var(--color-main);
	float: right;
}

.tooltipContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.tooltipIcon {
	margin-right: 10px;
	color: var(--color-disabled);
}

/* ExitedCare */

.exitedCareContainer {
	width: 100%;
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}
.exitedCareContent {
	margin: 16px;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}
.exitedCareSection {
	margin: 16px 0;
}

.goBack {
	display: flex;
	align-items: flex-start;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.expandableContainer {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	background-color: green;
}

.selectorListTileList {
	width: 100%;
}
