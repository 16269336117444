@import 'styles/vars.css';

/* EnableSwPrivilege */

.enableSwPrivilegeRoot {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: white;
	padding-bottom: 40px;
	margin-bottom: 8px;
	border-bottom: 1px solid #e8ecee;
	box-shadow: 0px 8px 0px var(--color-gray);
}

.enableSwPrivilegeContent {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: space-between;
}

.enableSwPrivilegeItem {
	position: relative;
	width: 100%;
	margin: 8px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.enableSwPrivilegeLabel {
	font-weight: bold;
}

.enableSwPrivilegeButton {
	white-space: nowrap;
	padding: 0;
}

/* SocialWorkerBasicInfo */

.socialWorkerBasicInfoRoot {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

/* SocialWorkers */

.swRoot {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	overflow-y: hidden;
}

.swRouteContainer {
	flex: 1;
	height: 100%;
	display: flex;
	position: absolute;
	width: 100%;
	z-index: 600;
}

.swRouteContent {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	background-color: white;
}
