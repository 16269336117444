@import 'styles/vars.css';

/* Note */

.postNoteContainerHeading {
	color: var(--color-disabled);
}

.noteDeleteIcon {
	color: var(--color-disabled);
	cursor: pointer;
}

.accordionDetails {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
}

/* PostNote */

.postNoteListRoot {
	width: 100%;
	height: min-content;
}

.postNoteListHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.postNoteListButton {
	cursor: pointer;
	color: var(--color-main);
	font-weight: normal;
}

.postNoteListAddButton {
	height: 36px;
	position: relative;
	right: 0px;
	margin: 16px 0 0;
	text-align: right;
}

/* PostNoteList */

.postNoteListRoot {
	width: 100%;
	height: auto;
}

/* AddPostNote */

.postNoteRoot {
	width: 100%;
	margin-bottom: 16px;
	position: relative;
	border-bottom: 1px solid var(--color-divider);
	padding-bottom: 8px;
}

.postNoteTextArea {
	width: 100%;
	margin: 8px 0 0;
}

.postNoteButtonContainer {
	margin-top: 8px !important;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	margin-top: 16px;
	margin-right: -5px;
	width: 100%;
	height: 40px;
}
