@import 'styles/vars.css';

/* Activities */

.activityFeedRoot {
	width: 100%;
	margin-top: 8px;
	display: block;
	position: relative;
	flex: 1;
	overflow-y: scroll;
	padding-left: 10%;
	padding-right: 10%;
}

@media only screen and (max-width: 660px) {
	.actvityFeedRoot {
		padding: 8px 5% 0 5%;
	}
}

.noPostRoot {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.noPostTitle {
	text-align: center;
	margin: 16px 8px;
	font-size: 16px;
}

/* Activity Item */

.activityRoot {
	position: relative;
	width: 100%;
	display: flex;
	max-width: var(--max-width);
	min-width: var(--min-width);
	border-bottom: 1px solid var(--color-divider);
	margin: 0 auto;
	height: 92px;
	flex: 1;
}

.activityLast {
	margin-bottom: 16px;
}

.activityLine {
	height: calc(100% + 1px);
	border-left: 2px solid var(--font-main);
	width: 2px;
	position: absolute;
	left: 0;
}

.activityOnlyLine {
	display: none;
}

.activityFirstLine {
	top: 26px;
	height: calc(100% - 25px);
}

.activityLastLine {
	top: calc(26px - 100%);
}

.activityCircle {
	position: absolute;
	top: 26px;
	left: -3px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--font-main);
}

.activityContent {
	height: 100%;
	width: 100%;
	padding: 24px 16px 24px 16px;
}

.activityDate {
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	color: var(--color-purple);
	margin-bottom: 8px;
}

.activityLabel {
	font-size: 20px;
	line-height: 24px;
	display: flex;
	justify-content: space-between;
}

.activityCategory {
	margin: -12px 0px 0px 0px;
}

@media only screen and (max-width: 660px) {
	.activityRoot {
		padding-left: 16px;
	}
	.activityLine {
		left: 16px;
	}
	.activityCircle {
		left: 13px;
	}
	.activityLabel {
		font-size: 16px;
	}
	.activityIcon {
		display: none;
	}
}
