@import 'styles/vars.css';

/* ChildBasicInfo */

.manageChildBasicInfoRoot {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

/* ChildEmail */

.emailInputResendButton {
	cursor: pointer;
	color: var(--color-main);
}

.headerText {
	flex: 1;
}

.basicHeader {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
}
