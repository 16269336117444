@import 'styles/vars.css';

/* LifebookNav */

.lifebookNavRoot {
	width: 100%;
	height: 112px;
	z-index: 2000;
	bottom: 0;
	background-color: var(--color-background-menu);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid var(--color-divider);
	box-shadow: 0px -3px 10px var(--color-divider);
}

.lifebookNavList {
	display: flex;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

@media only screen and (max-width: 660px) {
	.lifebookNavList {
		width: 100%;
	}
}

/* UseNavOptions */

.lifebookNavIndicator {
	position: absolute;
	top: -10px;
	right: -10px;
}

/* UseIconMapper */

.lifebookNavIcon {
	flex: 1 !important;
}

.lifebookNavIconLast {
	border-right: unset !important;
}

.lifebookNavActive {
	color: var(--color-main) !important;
}

.lifebookNavIconContainer {
	position: relative !important;
}

.lifebookNavIconLabel {
	margin-top: 16px !important;
}

@media only screen and (max-width: 660px) {
	.lifebookNavIcon {
		padding: unset !important;
		margin: 0 auto !important;
	}
}
