@import 'styles/vars.css';

/* AddImages */

.addImagePreviewButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	position: relative;
	margin-top: 8px;
}

.addFileContainer {
	position: relative;
	width: 100%;
	max-width: var(--max-width);
	height: 240px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	border-radius: 5px;
	border: 1px solid #bebebe;
}

/* AddImageDropzone */

.addImageRoot {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
	border: 1px solid #bebebe;
}

.addImageDropzoneSection {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.addImageDropzoneDivider {
	height: 80%;
	width: 1px;
	border-radius: 5px;
	border-left: 1px solid var(--color-divider);
}

.addImageDropzoneButton {
	margin-bottom: 12px;
	border-radius: 20px !important;
}
.addImageDropzoneActive {
	box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.8);
}

/* AddImagePreview */

.addImagePreview {
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.addImagePreviewIcon {
	margin: 12px 0 0 -48px;
}

.addImagePreviewImg {
	width: auto;
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	object-fit: contain;
}

/*   &:hover: {
        & button: {
            backgroundColor: theme.palette.background.gray;
        }
    }*/

/* AddImageLoading */

.loader {
	color: var(--color-main);
}

/* CarouselImage */

.carouselImageRoot {
	height: 80vh;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.carouselImage {
	max-width: calc(100% - 36px);
	height: 95%;
	max-height: 80vh;
	object-fit: contain;
	position: relative;
}

.carouselUnavailable {
	padding: 20%;
	max-height: unset;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	/* background-image: url(${window.location.origin + '/assets/ella-elephant.svg'}) */
	display: flex;
	justify-content: center;
	align-items: center;
}

.carouselImageUnavailableText {
	font-size: 16px;
	max-width: min-content;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	text-align: center;
	padding: 8px;
}

/* ImageUnavailable */

.addImagePreviewHeic {
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.addImagePreviewHeicText {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
}

/* LazyCarousel */

.carouselRoot {
	overflow: auto;
	display: block;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 80vh;
}

.carouselClose {
	position: absolute !important;
	top: 16px !important;
	right: 16px !important;
}

.carouselDelete {
	position: absolute !important;
	top: 16px !important;
	right: 60px !important;
}
