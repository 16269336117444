@import 'styles/vars.css';

/* CasesButtons */

.casesButtonContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 18%;
	width: 100%;
	overflow: hidden;
	box-shadow: 0px -3px 10px lightgray;
	background-color: #f7f7f7;
}

.casesInnerContainer {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 100%;
	width: 35%;
}

.casesInnerContainerSmall {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 100%;
	width: 75%;
}

/* ChildImage */

.childImageContainer {
	background-color: #f0f0f0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 8vw;
	height: 8vw;
	overflow: hidden;
}

.childImageContainerSm {
	background-color: #f0f0f0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.innerImage {
	height: 100%;
	width: auto;
}

/* InnerModal */

.noCaseText {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
}

.innerModal {
	width: 60%;
	height: 70vh;
	border-style: solid;
	background-color: white;
	display: flex;
	flex-direction: column;
	border-radius: 25;
	overflow: hidden;
}

.innerModalSm {
	width: 100%;
	height: 75vh;
	border-style: solid;
	background-color: white;
	display: flex;
	flex-direction: column;
	border-radius: 25;
	overflow: hidden;
}

.modalHeader {
	height: 22%;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	padding-top: 1.5%;
	box-shadow: 0px 5px 8px lightgray;
	background-color: #f7f7f7;
}

.headerText {
	color: black;
	font-size: 38px;
	padding-left: 2.5%;
	font-weight: 300;
}

.headerTextSm {
	color: black;
	font-size: 28px;
	padding-left: 5%;
	font-weight: 300;
}

.allChildren {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	justify-content: center;
	padding-top: 22px;
}

/* MappedChild */

.childContainer {
	border: 1px solid #bebebe;
	border-radius: 17px;
	width: 10vw;
	height: 13vw;
	display: flex;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	margin: 0 1% 2% 1%;
	padding-top: 2%;
}

.selected {
	border: 3px solid #00a0e8;
	padding-top: 1.8%;
}

.containerMedium {
	width: 16vw;
	height: 19vw;
}

.containerSmall {
	width: 25vw;
	height: 30vw;
	padding-top: 1%;
}

.containerTop {
	display: flex;
	justify-content: center;
	width: 9vw;
	height: 9vw;
}

.containerTopSmall {
	width: 20vw;
	height: 20vw;
}

.containerTopMedium {
	width: 12vw;
	height: 12vw;
}

.bottomContainer {
	margin-top: 3%;
	display: flex;
	align-items: center;
}

.bottomMedium {
	margin-top: 9%;
	font-size: 1.5vw;
}

.bottomSmall {
	font-size: 2.5vw;
}

.bold {
	font-weight: bold;
}
