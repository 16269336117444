@import './mixin.scss';
@import './variables.scss';

%select-style {
	display: block;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 500;
	line-height: 1.3;
	padding: 0.6em 1.4em 0.5em 0.8em;
	margin: 0;
	border: 1px solid rgba(255, 255, 255, 0);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('/assets/drop-arrow-24px.svg');
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 1.5em auto, 100%;

	option {
		font-weight: normal;
	}

	&:hover {
		border: 1px solid #888;
	}

	&:focus {
		border-color: #aaa;
		box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
		box-shadow: 0 0 0 3px -moz-mac-focusring;
		color: #222;
		outline: none;
	}

	&::-ms-expand {
		display: none;
	}
}

%button-style {
	border-radius: 6px;
	border: 1px solid rgb(138, 136, 136);
	background-color: #00a0e8;
	color: white;
	cursor: pointer;
}

%modal-style {
	background-color: white;
	border-radius: 5px;
	box-shadow: 6px 6px 5px -3px rgb(0, 0, 0);
}

%mid-content {
	animation: slide-in-mid 400ms;
	position: relative;
	height: 100%;
	width: 350px;
	min-width: 350px;
	background-color: white;
	box-sizing: border-box;
	z-index: 100;
	border-right: 1px solid $primary-divider;
	border-left: 1px solid $primary-divider;

	@include media-max($media-lg) {
		min-width: 250px;
		width: 250px;
	}

	// @include media-max($media-md) {
	// 	min-width: 300px;
	// 	width: 300px;
	// }

	@include media-max($media-sm) {
		z-index: 800;
		width: 100vw;
		max-width: 100vw;
		position: absolute;
	}
}

%button-container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 32px;
}

%right-content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	right: 0;
	height: 100vh;
	width: 100%;
	z-index: 50;

	@include media-max(800px) {
		max-width: 100%;
	}
	@include media-max($media-sm) {
		width: 100vw;
		max-width: 100vw;
	}
}

%form-header {
	position: relative;
	width: 100%;
	min-width: $content-min-width;
	max-width: $content-max-width;
	font-size: 20px;
	font-weight: 400;
	color: $primary-main;
	margin: 40px 0 16px 0;
}

%post {
	position: relative;
	width: 90%;
	max-width: $content-max-width;
	background-color: white;
	margin: 1px 0 7px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 16px;

	.post-info {
		position: relative;
		width: 100%;
		margin-bottom: 5px;
		font-size: 12px;

		.post-info-top {
			position: relative;
			height: 24px;
			display: flex;
			align-items: center;

			.post-menu {
				position: absolute;
				right: 0;
			}
		}

		.post-date {
			margin: 8px 0;
			background-color: white;
			padding-right: 10px;
			z-index: 10;
		}

		.post-title {
			font-size: 30px;
			line-height: 34px;
			font-weight: 700;
		}

		.post-body {
			font-family: 'Nunito', sans-serif;
			margin: 8px 0;
			> span {
				color: $primary-main;
				cursor: pointer;
			}
		}

		.post-variant-color {
			color: #796eff;
		}
		.highlight-color {
			color: #FF5263;
		}
	}

	.post-photo {
		display: inline-block;
		background-color: white;
		width: 100%;
		background-size: contain;
		background-repeat: no-repeat;

		img {
			width: 100%;
			position: relative;
		}
	}
}

%info-top-bar {
	height: 12px;
	width: 100%;
	border-radius: 10px 10px 0 0;
}
