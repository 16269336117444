@import 'styles/vars.css';

/* ContributorBasicInfo */

.contributorRoot {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

/* ContributorSelectorList */

.selectorListRoot {
	background-color: white;
	display: flex;
	flex-direction: column;
	margin: 16px 0 16px 0;
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 100%;
}

/* FullChildTile */

.childTileContainer {
	position: absolute;
	right: 16px;
}

@media only screen and (max-width: 660px) {
	.childTileContainer {
		right: '8px';
	}
}

/* RelationshipTile */

.contributorListTile {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	display: flex;
	align-items: center;
	background-color: var(--color-gray);
	border-bottom: 1px solid var(--color-divider);
}

.contributorNoAvatar {
	margin-left: 16px;
}

.contributorListName {
	display: flex;
	align-items: center;
	position: relative;
	flex: 1;
}

/* Contributor */

.contributorContainer {
	flex: 1;
	height: 100%;
	display: flex;
	position: relative;
}

.contributorContent {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	background-color: white;
}
