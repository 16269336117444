@import 'styles/vars.css';

/* ProfileEmail */

.profileEmail {
	cursor: pointer;
	margin-right: 8px;
	color: var(--color-main);
}

.profileEmailStatic {
	display: flex;
	justify-content: space-between;
}

/* ProfileName */

.profileNameContainer {
	position: relative;
	flex-direction: column;
	display: flex;
	width: 100%;
}

/* ProfileBasics */

.profileRoot {
	position: relative;
	padding-top: 40px;
	width: 100%;
	max-width: var(--max-width);
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 0 auto 8px;
}

@media only screen and (max-width: 660px) {
	.profileRoot {
		padding-top: 8px;
	}
}

/* ChildToggles */

.childToggleInfoContainer {
	margin-top: 16px;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	height: auto;
	color: var(--font-main);
	display: flex;
	align-items: center;
	margin: 0 auto;
}

.childToggleInfo {
	margin-left: 8px;
}

/* NotificationToggles */

.notificationsContainer {
	margin-top: 16px;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	height: auto;
	color: var(--font-main);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0 auto;
}

.notificationsAll {
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: var(--max-width);
	min-width: var(--min-width);
	margin: 0 auto;
}

.notificationsCheck {
	font-weight: normal;
}

/* ProfilePassword */

.profilePasswordField {
	margin: 10px 0;
	width: 100%;
	height: 50px;
}

.profilePasswordRoot {
	width: 100%;
	padding-bottom: 8px;
	padding: 8px 0;
}

.profilePasswordEdit {
	cursor: pointer;
	color: var(--color-main);
	float: right;
}

/* OwnerProfile */

.settingsContent {
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	height: 100%;
	padding: 24px 0 0 0;
	overflow-y: auto;
}

@media only screen and (max-width: 660px) {
	.settingsContent {
		padding: 24px 8px 8px 8px;
	}
}

/* useArrayMappers */

.arrayMapperProfileField {
	margin: 10px 0;
	width: 100%;
	height: 50px;
}

/* AccountImages */

/*ChildImage */

.imageRoot {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
}

.imageContent {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

/* Billing */

.billing {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.billingStaticText {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.label {
	width: 500px !important;
	margin-right: 200px;
	max-width: 750px;
}

/* MultiFactor */

.formSectionContainer {
	margin-top: 16px;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	height: auto;
	color: var(--font-main);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
}

/* Modal */

.modalContainer {
	background-color: white;
	height: 250px;
	width: 450px;
	border-radius: 15px;
	padding-left: 1%;
	padding-right: 1%;
	padding-top: 1%;
	display: flex;
	flex-direction: column;
}

.textContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	padding-right: 5%;
	padding-left: 5%;
	font-size: 24px;
}

.lowerTextContainer {
	padding-left: 5%;
	padding-right: 5%;
	margin-bottom: 5%;
}

.inputContainer {
	display: flex;
	flex: 2;
	padding-left: 3%;
	padding-right: 5%;
	padding-top: 2%;
	width: 100%;
	justify-content: center;
}

.buttonContainer {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding-bottom: 3%;
	padding-right: 5%;
	flex: 1;
}

/* Auth Input */

.authContainer {
	height: 75%;
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	padding-left: 2%;
	padding-right: 2%;
	margin-bottom: 2%;
}

.authInput {
	height: 80%;
	width: 15%;
	margin-left: 2%;
	font-size: 300%;
}
