@import 'styles/vars.css';

/* Global Casa */

.casaCenteredFlex {
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* Permissions */

.notificationsToggleInfoContainer {
	margin-top: 16px;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	height: auto;
	color: var(--font-main);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0 auto;
}

.notificationToggleAll {
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: var(--max-width);
	min-width: var(--min-width);
	margin: 0 auto;
}

.notificationsToggleCheck {
	font-weight: normal;
}

/* RemoveChildButton */

.casaRemoveButton {
	position: relative;
	width: 100%;
	margin: 8px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--color-error);
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.casaButtonLabel {
	font-weight: bold;
}

.casaRemoveButtonContainer {
	white-space: nowrap;
	padding: 0;
}

/* CasaSelector */

.selectorListRoot {
	background-color: white;
	display: flex;
	flex-direction: column;
	margin: 16px 0 16px 0;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.selectorListStandard {
	width: 95%;
}

.selectorListFull {
	width: 100%;
}

.selectorListTextField {
	margin: 16px 0;
}

.selectorListSubheader {
	font-weight: 500;
	border-top: 1px solid var(--color-disabled);
	background-color: white;
}

.selectorListTileList {
	width: 100%;
}

.selectorListButtonContainer {
	position: absolute;
	right: 16px;
}

@media only screen and (max-width: 660px) {
	.selectorListButtonContainer {
		right: 8px;
	}
}

.selectorListActiveTile {
	border-left: 8px solid var(--color-main);
}

/* CasaSelectListTile */

.selectListTileIndicator {
	position: absolute;
	right: 10px;
}

.roundAvatar {
	margin: 10;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.04em;
	background-color: var(--color-main);
}

.selectListTileActive {
	font-weight: 700;
	border-left: 8px solid var(--color-light);
	background-color: #e8ecee;
}

/* ErrorScreen */

.welcomeRoot {
	height: calc(100% - 44px);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.welcomeSubject {
	color: red;
	font-size: 16px;
	line-height: 18px;
	font-weight: 700;
	width: 50%;
	text-align: center;
}
