$font-default: 'Nunito', sans-serif;
$primary-blue: #008CE3;
$primary-dark: #222B37;
$primary-main: #42BEEE;
$primary-gray: #F6F8F9;
$primary-white: #FFFFFF;
$primary-disabled: #D3D3D3;
$button-main: #008CE3;
$active-main: #42BEEE;
$active-dark: #222B37;
$modal-background: rgba(46, 46, 46, 0.692);
$light-background: rgb(255, 255, 255);
$primary-purple: #796EFF; // used in edit forms
$secondary-main: #25E8C8;
$secondary-font: #6F7782;
$primary-divider: #E8ECEE;
$background-gray: #f6f8f9;
$content-max-width: 722px;
$content-max-width-md: 500px;
$content-min-width: 300px;
$media-sm: 660px;
$media-md: 960px;
$media-lg: 1280px;
$media-xl: 1920px;
$padding-content-width: 0 8%;