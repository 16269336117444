@import 'styles/vars.css';

/* AdminBasicInfo */

.adminInfoContainer {
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 100%;
}

/* Administrators */

.adminFullContainer {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	overflow-y: hidden;
}

.adminContent {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	background-color: white;
}

.adminContainer {
	flex: 1;
	height: 100%;
	display: flex;
	position: absolute;
	width: 100%;
	z-index: 600;
}
