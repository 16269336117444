@import 'styles/vars.css';

/* Vault */

.vaultContainer {
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
}

.vaultContent {
	background-color: #ffffff;
	z-index: 400;
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	width: 100%;
}

.vaultFeed {
	position: relative;
	height: calc(100% - 116px);
	width: 100%;
	max-width: 100vw;
	z-index: 0;
}

/* AddVaultFiles */

.addFile {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5%;
	margin-top: 2%;
}

.fileNameContainer {
	display: flex;
	flex: 1;
	margin-left: 2%;
	margin-right: 2%;
}

.fileName {
	width: 95%;
}

/* VaultFile */

.fullVaultFileItem {
	display: flex;
	flex-direction: column;
}

/* VaultFileItems */

.vaultFileItems {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow-y: scroll;
	margin-bottom: 5%;
}

.mappedFiles {
	display: flex;
	flex: 1;
	height: 100%;
	flex-direction: column;
}

/* VaultFileOptions */

.vaultFileItemContainer {
	flex-direction: row;
	display: flex;
	width: 100%;
	align-items: center;
	flex: 1;
	justify-content: space-between;
	margin-top: 2%;
}

.fileInput {
	display: none;
}

.downloadIconActive {
	color: var(--color-main);
	cursor: pointer;
}

.downloadIconChild {
	color: var(--color-main);
	cursor: pointer;
	margin-right: 2%;
}

.downloadIconInactive {
	color: var(--color-disabled);
	cursor: pointer;
}

.updateAddContainer {
	flex: 2;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.vaultDateContainer {
	flex: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 1%;
}

.vaultIconContainer {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.submitButtonContainer {
	flex: 2;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* VaultFiles */

.fileFullContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.checkbox {
	color: var(--color-main);
	margin-right: 1%;
}

/* VaultHeader */

.vaultHeader {
	display: flex;
	align-items: center;
	width: 100%;
}

.vaultHeaderLabel {
	flex: 1;
	display: flex;
	align-items: flex-end;
}

.vaultExpandIcon {
	cursor: pointer;
}

.infoIcon {
	color: var(--color-main);
}

/* VaultFileOther */

.otherTextLabel {
	display: flex;
	flex: 3;
}

.vaultDateContainerOther {
	flex: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 1%;
}

.childFilesOther {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.downloadIconActiveChild {
	color: var(--color-main);
	cursor: pointer;
	margin-right: 2%;
}

@import 'styles/vars.css';

/* Activities */

.activityFeedRoot {
	width: 100%;
	margin-top: 8px;
	display: block;
	position: relative;
	flex: 1;
	overflow-y: scroll;
	padding-left: 10%;
	padding-right: 10%;
}

@media only screen and (max-width: 660px) {
	.actvityFeedRoot {
		padding: 8px 5% 0 5%;
	}
}

.noPostRoot {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.noPostTitle {
	text-align: center;
	margin: 16px 8px;
	font-size: 16px;
}

/* Activity Item */

.activityRoot {
	position: relative;
	width: 100%;
	display: flex;
	max-width: var(--max-width);
	min-width: var(--min-width);
	border-bottom: 1px solid var(--color-divider);
	margin: 0 auto;
	height: 92px;
	flex: 1;
}

.activityLast {
	margin-bottom: 16px;
}

.activityLine {
	height: calc(100% + 1px);
	border-left: 2px solid var(--font-main);
	width: 2px;
	position: absolute;
	left: 0;
}

.activityOnlyLine {
	display: none;
}

.activityFirstLine {
	top: 26px;
	height: calc(100% - 25px);
}

.activityLastLine {
	top: calc(26px - 100%);
}

.activityCircle {
	position: absolute;
	top: 26px;
	left: -3px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--font-main);
}

.activityContent {
	height: 100%;
	width: 100%;
	padding: 24px 16px 24px 16px;
}

.activityDate {
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	color: var(--color-purple);
	margin-bottom: 8px;
}

.activityLabel {
	font-size: 20px;
	line-height: 24px;
	display: flex;
	justify-content: space-between;
}

.activityCategory {
	margin: -12px 0px 0px 0px;
}

@media only screen and (max-width: 660px) {
	.activityRoot {
		padding-left: 16px;
	}
	.activityLine {
		left: 16px;
	}
	.activityCircle {
		left: 13px;
	}
	.activityLabel {
		font-size: 16px;
	}
	.activityIcon {
		display: none;
	}
}

.vaultChildDownload {
	display: flex;
	flex-direction: 'row';
	align-items: center;
}
