@import 'styles/vars.css';

/* RPBasicInfo */

.rpBasicInfoRoot {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

/* ResourceParents */

.rpRouteContainer {
	flex: 1;
	height: 100%;
	display: flex;
	position: relative;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 600;
}

.rpRouteContent {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	background-color: white;
}
