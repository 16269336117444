/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@import './_variables.scss';
@import './_mixin.scss';
@import './_partials.scss';

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

#preload-screen {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

* {
	-webkit-overflow-scrolling: touch;
}

.App {
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	font-family: 'Nunito', sans-serif;
	font-style: normal;
	font-weight: normal;
	width: 100vw;
	height: 100vh;

	h3 {
		margin: 10px 0;
	}

	.app-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		overflow: hidden;
		.route-container {
			position: relative;
			right: 0;
			display: flex;
			justify-content: center;
			font-family: 'Nunito', sans-serif;
			height: 100%;
			flex: 1;
			max-width: 100vw;
			overflow: hidden;
			z-index: 150;
			transition: max-width 500ms;
		}
	}
}

.app-warning {
	width: 100vw;
	height: 100vh;
	background-color: white;
	z-index: 1000;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 25px;
	img {
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 767px) {
	_::-webkit-full-page-media,
	_:future,
	:root .safari_only {
		-webkit-overflow-scrolling: touch;
		padding-bottom: 65px; //resize
	}
}

@keyframes slide-in {
	0% {
		transform: translate(-1000px, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}

@keyframes slide-out {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-1000px, 0);
	}
}

@keyframes slide-up-in {
	0% {
		top: 100%;
	}
	100% {
		top: 0;
	}
}

@keyframes slide-down-out {
	0% {
		top: 0;
	}
	100% {
		top: 100%;
	}
}

@keyframes zoom-appear {
	0% {
		transform: scale(0, 0);
	}
	100% {
		transform: scale(1, 1);
	}
}

@keyframes zoom-disappear {
	0% {
		transform: scale(1, 1);
	}
	100% {
		transform: scale(0, 0);
	}
}

img {
	user-drag: none;
	-webkit-user-drag: none;
}

.list-container {
	@extend %mid-content;
	display: flex;
	flex-direction: column;
	border-right: 1px solid $primary-divider;

	.tile-list {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		flex-direction: column;
	}
	@include media-max($media-sm) {
	}

	.list-block {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		background-color: rgba(0, 0, 0, 0.602);
	}
	@include media-max($media-sm) {
		width: 100vw;
		animation: zoom-appear 100ms ease-in-out;
	}
}

.mobile-list-hide {
	@include media-max($media-sm) {
		animation: zoom-disappear 100ms ease-in-out;
		animation-fill-mode: forwards;
	}
}

.list-hide {
	animation: zoom-disappear 400ms;
	width: 0;
	animation-fill-mode: forwards;
}

.lifebooks-container {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100vw;
	height: 100%;

	.lifebooks-right-content {
		@extend %right-content;
		background-color: #ffffff;
		transition: max-width 500ms ease-in-out;
		z-index: 400;
		display: flex;
		height: 100%;
	}

	.lifebooks-right-full {
		max-width: 100vw;
		position: absolute;
		right: 0;
	}

	@include media-max(450px) {
		.mid-content-active {
			background-color: red;
		}
	}
}

.add-post-content {
	width: 95%;
	min-width: $content-min-width;
	max-width: $content-max-width;
	padding-bottom: 16px;
	.add-post-header {
		@extend %form-header;
		min-height: 16px;
	}
	.add-post-form {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		height: 55%;
		padding-top: 8px;
		.edit-post-title {
			margin: 16px 0 8px 0;
			font-size: 20px;
		}
		.add-post-input-field {
			position: relative;
			width: 100%;
			margin: 8px 0;
		}
		.add-post-button-container {
			@extend %button-container;
			position: relative;
			margin-top: 16px;
			margin-right: -5px;
			width: 100%;
			height: 40px;
		}
	}
}
@include media-max($media-sm) {
	width: 100vw;
	height: calc(100% - 60px);
	box-sizing: border-box;
	border-radius: 0;
	.add-post-content {
		.add-post-header {
			margin-top: 8px;
		}
	}
	.add-post-form {
		width: 90%;
		height: 90%;
		.input-body {
			height: calc(100% - 180px);
		}
		.add-post-button-container {
			width: 100%;
		}
	}
}

.post-container {
	position: relative;
	width: 100%;
	min-width: 400px;
	min-height: auto;
	background-color: white;
	margin-bottom: 24px;
	border-bottom: 1px solid #e8ecee;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 12% 40px 12%;
	box-shadow: 0px 8px 0px $background-gray;
	.post-content {
		width: 95%;
		min-height: auto;
		max-width: $content-max-width;
		position: relative;

		.post-menu {
			position: absolute;
			right: 0;
			display: flex;
			align-items: center;
			z-index: 100;
		}

		.post-info {
			position: relative;
			width: 100%;
			margin-bottom: 16px;
			font-size: 14px;
			color: #151b26;

			.post-info-top {
				position: relative;
				font-size: 12px;

				font-weight: 500;
				display: flex;
				flex-direction: column;
				align-items: left;
				line-height: 24px;
				.post-creator-name {
					font-weight: bold;
					font-size: 12px;
					color: $secondary-font;
					margin: 0 0 4px 0;
				}
				.post-date {
					margin: 4px 0 0;
					font-size: 12px;
					font-weight: 500;
					z-index: 10;
					color: $secondary-font;
				}
				.post-category-container {
					display: flex;
					margin: 2px 0 20px;
					align-items: center;
					text-transform: uppercase;
					.post-category-icon {
						width: 24px;
						height: 24px;
						margin-right: 4px;
					}
				}
			}

			.post-title {
				margin: 4px 0 0;
				font-size: 30px;
				line-height: 34px;
				font-weight: 400;
				color: #151b26;
			}

			.post-nsp-info-container {
				margin-top: 16px;
				width: 100%;
				height: auto;
				color: $secondary-font;
				display: flex;
				justify-content: flex-start;

				.post-nsp-info {
					margin-left: 8px;
				}
			}

			.post-variant-color {
				color: #796eff;
			}
			.highlight-color {
				color: #ff5263;
			}
		}

		.post-photo {
			display: inline-block;
			width: 100%;
			background-size: contain;
			background-repeat: no-repeat;

			img {
				width: 100%;
				min-height: auto;
				position: relative;
			}
		}

		.post-fade-opacity {
			opacity: 0.25;
			transition: opacity 500ms;
		}
	}

	@include media-max($media-sm) {
		min-width: 0;
		padding: 8px 1.5%;

		.post-content {
			padding: 0 8px;
			.post-menu {
				right: 1.5%;
			}
		}

		.post-info {
			width: 95%;
		}
	}
}

.post-label {
	font-weight: 500;
	height: 24px;
	font-size: 12px;
	line-height: 12px;
	padding: 6px 8px;
	letter-spacing: 0.08em;
	border-radius: 4px;
	display: flex;
	align-items: center;
	margin-left: 5px;
}
.pending-label {
	background-color: $active-main;
}
.suggested-label {
	background-color: $secondary-main;
	color: rgb(61, 60, 60);
}

.nsp-label {
	background-color: $primary-purple;
	color: white;
	font-size: 10px;
}

.post-container:hover {
	.post-fade-opacity {
		opacity: 1;
	}
}

::-webkit-scrollbar {
	width: 0px; /* Remove scrollbar space */
	background: transparent;
}

.select-list-tile {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	display: flex;
	align-items: center;
	background-color: $primary-gray;
	border-bottom: 1px solid $primary-divider;

	.select-list-tile-img {
		background-color: darkgray;
		width: 60px;
		height: 60px;
		border-radius: 5px;
		margin: 0 10px 0 20px;
	}
	.select-list-tile-no-avatar {
		margin-left: 16px;
	}

	.select-list-tile-name {
		display: flex;
		align-items: center;
		position: relative;
		flex: 1;
	}

	.select-list-tile-menu-toggle {
		position: relative;
		display: flex;
		align-items: flex-end;

		.select-list-tile-menu {
			width: 80px;
			height: 50px;
			flex-direction: column;
			justify-content: space-around;
			background-color: white;
			font-weight: normal;
			font-style: normal;
			position: absolute;
			right: 20px;
			text-indent: 4px;
			top: 0;
			box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.24);

			> div {
				height: 48%;
				line-height: 25px;
			}
			> div:hover {
				background-color: $primary-main;
			}
		}
	}
	.select-list-tile-menu-toggle::after {
		content: '\2807';
		font-size: 25px;
		font-style: normal;
		font-weight: normal;
	}
}
.select-list-tile-active {
	font-weight: 700;
	border-left: 8px solid $active-main;
	background-color: #e8ecee;
}

.alternate-color {
	background-color: $background-gray;
}

.select-list-tile-collapse {
	max-height: 0px;
	border: 0;
	animation: collapse 500ms ease-in-out;
}

@keyframes collapse {
	0% {
		max-height: 80px;
	}
	100% {
		max-height: 0px;
		border-bottom: 0;
	}
}

@keyframes expand {
	0% {
		max-height: 0px;
	}
	100% {
		max-height: 80px;
		border-bottom: 1px solid lightgray;
	}
}

.selectable:hover {
	background-color: #e3e7e9;
	cursor: pointer;
}

.homes-container {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	overflow-y: hidden;
}

.select-list-tile {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	display: flex;
	align-items: center;
	background-color: $primary-gray;
	border-bottom: 1px solid $primary-divider;

	.select-list-tile-img {
		background-color: darkgray;
		width: 60px;
		height: 60px;
		border-radius: 5px;
		margin: 0 10px 0 20px;
	}
	.select-list-tile-no-avatar {
		margin-left: 16px;
	}

	.select-list-tile-name {
		display: flex;
		align-items: center;
		position: relative;
		flex: 1;
	}

	.select-list-tile-menu-toggle {
		position: relative;
		display: flex;
		align-items: flex-end;

		.select-list-tile-menu {
			width: 80px;
			height: 50px;
			flex-direction: column;
			justify-content: space-around;
			background-color: white;
			font-weight: normal;
			font-style: normal;
			position: absolute;
			right: 20px;
			text-indent: 4px;
			top: 0;
			box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.24);

			> div {
				height: 48%;
				line-height: 25px;
			}
			> div:hover {
				background-color: $primary-main;
			}
		}
	}
	.select-list-tile-menu-toggle::after {
		content: '\2807';
		font-size: 25px;
		font-style: normal;
		font-weight: normal;
	}
}
.select-list-tile-active {
	font-weight: 700;
	border-left: 8px solid $active-main;
	background-color: #e8ecee;
}

.alternate-color {
	background-color: $background-gray;
}

.select-list-tile-collapse {
	max-height: 0px;
	border: 0;
	animation: collapse 500ms ease-in-out;
}

@keyframes collapse {
	0% {
		max-height: 80px;
	}
	100% {
		max-height: 0px;
		border-bottom: 0;
	}
}

@keyframes expand {
	0% {
		max-height: 0px;
	}
	100% {
		max-height: 80px;
		border-bottom: 1px solid lightgray;
	}
}

.selectable:hover {
	background-color: #e3e7e9;
	cursor: pointer;
}

.first-tile-container {
	width: 100%;
	font-size: 12px;
	font-weight: 700;
	color: #6f7782;
	height: 32px;
	display: flex;
	align-items: center;
	padding-left: 16px;
	border-bottom: 1px solid $primary-divider;
}

.modal-container {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 600;
}

.add-file-container {
	position: relative;
	width: 100%;
	max-width: $content-max-width;
	height: 240px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	border-radius: 5px;
	border: 1px solid #bebebe;
}

.list-sort-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	height: auto;
	z-index: 100;
	border-bottom: 1px solid $primary-divider;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.04),
		0px 1px 2px rgba(0, 0, 0, 0.04);

	.list-sort {
		position: relative;
		top: 0;
		background-color: $primary-gray;
		height: 44px;
		width: 100%;
		display: flex;
		align-items: center;
		border-top: 1px solid $primary-divider;

		.list-sort-by {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			height: 44px;
			text-align: right;
		}

		.list-sort-collapse {
			padding-left: 10px;
			display: flex;
			align-items: center;
		}
		.list-sort-option-label {
			font-family: $font-default;
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			text-transform: uppercase;
			color: #6f7782;
			margin-left: 4px;
		}
	}

	@include media-max($media-md) {
		min-width: 170px;
		padding-left: 0;

		.list-sort {
			flex-direction: column;
			align-items: flex-start;

			.list-sort-by {
				width: 100%;
				text-align: left;
			}

			select {
				width: 80%;
				margin: 0 10px;
			}
		}
	}
}

.loader-window {
	position: fixed;
	z-index: 9000;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.503);
	display: flex;
	justify-content: center;
	align-items: center;

	.loader-container {
		position: fixed;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 200px;
		width: 200px;
		font-size: 20px;
		color: $primary-main;
	}
}

@keyframes loading-text {
	0% {
		content: 'Loading';
	}
	25% {
		content: 'Loading.';
	}
	50% {
		content: 'Loading..';
	}
	75% {
		content: 'Loading...';
	}
}

.password-confirm-container {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0px;
	left: 0px;
	background-color: rgba(80, 80, 80, 0.8);
	width: 100vw;
	height: 100vh;
	z-index: 115;

	.password-confirm-card {
		@extend %modal-style;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 400px;
		height: auto;

		h3 {
			max-width: 85%;
		}

		.password-confirm-error {
			color: red;
			height: 24px;
			margin: 5px 0;
		}

		.password-confirm-button-container {
			@extend %button-container;
			width: 80%;
			margin-bottom: 16px;

			button {
				margin: 0 5px;
			}
		}
	}
}

.password-confirm-hide {
	display: none;
}
