@import 'styles/vars.css';

/* AuditInviteForm */

.auditInviteRoot {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.auditInviteButton {
	height: 36px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

/* AuditorList */

.auditorListRoot {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.auditorListContent {
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.auditorListHeader {
	display: flex;
	flex-direction: row;
	width: 100%;
}

/* AuditorListItem */

.auditorListItemRoot {
	width: 100%;
	display: flex;
	margin: 4px 0;
}

.auditorListItemInfo {
	display: flex;
	flex-direction: row;
	width: 75%;
}

.auditorListRevokeButton {
	color: var(--color-main);
	float: right;
	cursor: pointer;
	flex: 1;
	text-align: right;
}

.auditorItem {
	flex: 1;
	display: flex;
	width: 200px;
}

/* ChildAudit */

.manageChildAuditRoot {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.manageChildAuditRow {
	position: relative;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 8px 0;
}

.manageChildAuditLabel {
	font-weight: bold;
}

.manageChildAuditButton {
	white-space: nowrap;
	padding: 0;
}

.headerText {
	flex: 1;
}

.basicHeader {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
}
