@import 'styles/vars.css';

/* SupportFormTextFields */

.supportFormField {
	width: 100%;
}

/* SupportForm */

.supportFormContent {
	width: 100%;
	height: 100%;
	padding: 24px 8px 16px 8px;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
}

.supportFormField {
	width: 100%;
}

.supportFormReCaptcha {
	width: 100%;
	margin: 12px 0;
}

.buttonContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 5%;
}

/* SupportManual */

.supportManualContainer {
	width: 100%;
	height: 100%;
}

.supportManualContent {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
}

.supportObject {
	width: 100%;
	height: 100%;
}

.supportManualAltTextContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 8px;
}

/* SupportTile */

.tileContainer {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	display: flex;
	align-items: center;
	background-color: white;
	border-bottom: 1px solid var(--color-divider);
	padding-left: 8%;
	cursor: pointer;
}
.tileContainerActive {
	padding-left: 5%;
	border-left: 6px solid #00a0e8;
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	display: flex;
	align-items: center;
	background-color: white;
	border-bottom: 1px solid var(--color-divider);
	cursor: pointer;
}

.listTileName {
	display: flex;
	align-items: center;
	position: relative;
	flex: 1;
}

/* SupportNav */

.supportFirstTile {
	font-weight: 900;
	margin-left: 25px;
}

.supportTileContainer {
	height: 60px;
	display: flex;
	align-items: center;
}

.supportLabel {
	height: 8%;
	padding-top: 5%;
}

.fullSupportNav {
	width: 20%;
	border: 2px solid var(--color-divider);
}

.fullSupportNavSmall {
	border: 2px solid var(--color-divider);
	display: flex;
	flex: 1;
	margin-top: 2%;
	overflow: scroll;
}

.tileList {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	flex-direction: column;
}

.tileListSmall {
	width: 100%;
}

.supportTile {
	font-weight: 900;
	margin-left: 25px;
	height: 60px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.supportActiveTile {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	max-height: 72px;
	overflow: hidden;
	background-color: #f4f4f4;
	border-bottom: 1px solid var(--color-divider);
	border-left: 6px solid #00a0e8;
	font-weight: 900;
	padding-left: 19px;
	height: 60px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

/* SupportVideo */

.videoWrapper {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
}

.videoWrapperSmall {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.supportVideoContainer {
	width: 100%;
	height: 100%;
}

.supportVideoContent {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-gray);
}

.supportVideoContentSmall {
	display: flex;
	flex: 1;
	background-color: var(--color-gray);
	align-items: center;
	justify-content: center;
}

.supportVideo {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
}

.videoThumb {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Support */

.supportRoot {
	width: 100vw;
	display: flex;
	align-items: center;
	background-color: white;
}
