@import 'styles/vars.css';

/* BranchBasicInfo and AgencyBasicInfo */

.branchInfoContainer {
	max-width: var(--max-width);
	min-width: var(--min-width);
	width: 100%;
}

/* Agency */

.agencyFullContainer {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	overflow-y: hidden;
}

.inviteContainer {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 600;
}

.agencySwitchContainer {
	position: relative;
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.agencyContainer {
	flex: 1;
	height: 100%;
	display: flex;
	position: absolute;
	width: 100%;
	z-index: 600;
}
