@import 'styles/vars.css';

/* Calendar */

.fullCalendar {
	height: 100%;
	width: 100%;
	max-width: 1200px;
	display: flex;
	margin-top: 4%;
	justify-content: center;
}

.calendarContent {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
}

.calendarContainer {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100vw;
	height: 100%;
}

.calendarRightContent {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	right: 0;
	height: 100vh;
	width: 100%;
	background-color: #ffffff;
	transition: max-width 500ms ease-in-out;
	z-index: 400;
}

.calendarRightFull {
	max-width: 100vw;
	position: absolute;
	right: 0;
}

/* EventModal */

.fullEventModal {
	height: 60%;
	width: 50%;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.fullEventModalMobile {
	height: 75%;
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.header {
	font-size: 24px;
	width: 75%;
	height: 15%;
}

.titleContainer {
	width: 75%;
	margin-bottom: 2%;
}

.toggle {
	display: flex;
	align-items: center;
	width: 100%;
}

.labelStyle {
	margin-right: 5%;
}

.calendarNotify {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 75%;
}

.calendarDescription {
	width: 75%;
}

.calendarDescriptionContainer {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	margin-bottom: 2%;
}

.title {
	width: 100%;
}

.helpIcon {
	color: var(--color-main);
	margin-right: 2%;
}

.datesContainer {
	width: 75%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	margin-bottom: 2%;
}

.dateContainer {
	width: 45%;
}

.dateContainerFull {
	width: 45%;
}

.buttonContainer {
	justify-content: flex-end;
	display: flex;
	width: 100%;
	padding-right: 5%;
}

/* ViewCalendar */

.viewContainer {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 5%;
}

.fullConfirmContainer {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 20%;
	width: 40%;
	background-color: white;
}

.confirmHeader {
	font-size: 18px;
	flex: 1;
	display: flex;
	align-items: center;
}

.confirmButtonContainer {
	flex: 1;
}
