:root {
	--color-light: #43bbf7;
	--color-main: #00a0e8;
	--color-divider: #e8ecee;
	--color-error: red;
	--color-gray: #f6f8f9;
	--color-purple: #796eff;
	--color-disabled: #babcbc;
	--color-background-active: #c3e6fd;
	--color-background-nav: #222222;
	--color-background-menu: #f7f7f7;
	--color-contrast-text: #ffffff;
	--color-divider-dark: #bebebe;
	--font-main: #6f7782;
	--font-default: #00000;
	--font-family-default: 'Nunito, sans-serif';
	--max-width: 722px;
	--min-width: 300px;
	--xs: 0;
	--sm: 660;
	--md: 960;
	--lg: 1280;
	--xl: 1920;
}
