@import 'styles/vars.css';

/* Global Child Admin Styles */

.childAdminItemWrapper {
	position: relative;
	width: 100%;
	margin: 8px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.childAdminBoldLabel {
	font-weight: bold;
}

.childAdminButtonContainer {
	white-space: nowrap;
	padding: 0;
}

/* ExportPdfForm */

.exportPdfFormRoot {
	border-bottom: 1px solid var(--color-divider);
	margin-bottom: 24px;
	padding-bottom: 12px;
}
.exportPdfFormField {
	position: relative;
	width: 100%;
	margin: 8px 0;
}
.exportPdfFormButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

/* ExportPdfInfo */

.exportPdfInfoRoot {
	margin-bottom: 12px;
}

.exportPdfInfoList {
	list-style-type: circle;
}

.exportPdfInfoItem {
	margin-left: 24px;
}

/* ChildAdmin */

.childAdminWarn {
	color: var(--color-error);
}

.childAdminRoot {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: white;
	padding-bottom: 40px;
	margin-bottom: 8px;
	align-items: center;
}

.childAdminContent {
	position: relative;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: space-between;
	width: 100%;
}

.headerText {
	flex: 1;
}

.basicHeader {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
}
