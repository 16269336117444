@import 'styles/vars.css';

/* Lifebooks */

.lifebooksContent {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
}

.lifebooksContainer {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100vw;
	height: 100%;
}

.lifebooksRightContent {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	right: 0;
	height: 100vh;
	width: 100%;
	background-color: #ffffff;
	transition: max-width 500ms ease-in-out;
	z-index: 400;
}

.lifebooksRightFull {
	max-width: 100vw;
	position: absolute;
	right: 0;
}
