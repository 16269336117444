@import 'styles/vars.css';

/* PrivacyPolicy */

.privacyPolicyCheckbox {
	width: 100%;
	max-width: var(--max-width);
	margin: 8px 0;
}

/* Invite */

.agencyInviteContent {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.inviteContainer {
	position: relative;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.inviteContent {
	margin: 0 auto;
	width: 90%;
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100%;
	overflow: auto;
}

.agencyInviteSectionHeader {
	width: 100%;
	max-width: var(--max-width);
}
