@import 'styles/vars.css';

/* DashboardToggle */

.dashboardToggleContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;
	z-index: 800;
}

@media only screen and (max-width: 450px) {
	.dashboardToggleContainer {
		height: 60px;
	}
}

.dashboardToggleButton {
	border-right: unset !important;
}

.dashboardToggleIcon {
	width: 30px;
	height: 30px;
}

/* DeleteButton */

.deleteButton {
	border: 1px solid var(--color-error) !important;
	border-style: solid;
	border-width: 1px;
	background-color: white !important;
	color: var(--color-error) !important;
	margin-left: 8px;
	font-size: 12px;
	padding: 8px 16px;
	letter-spacing: 0.08em;
	border-radius: 24px !important;
	box-sizing: border-box;
}

.deleteButton:hover {
	background-color: var(--color-error) !important;
	color: white !important;
	font-weight: bold !important;
}

/* OptionButton */

.optionButton {
	border: var(--color-main);
	border-style: solid;
	border-width: 1px;
	background-color: white;
	color: #00a0e8;
	font-size: 14px;
	padding: 8px 16px;
	letter-spacing: 0.08em;
	border-radius: 20px;
	box-sizing: border-box;
}

.optionButton:hover {
	color: #00a0e8;
	background-color: white;
}

@media only screen and (max-width: 960px) {
	.optionButton {
		font-size: 12px;
	}
}

/* PrimaryButton */

.primaryButton {
	background-color: var(--color-main) !important;
	align-items: center !important;
	justify-content: center !important;
	color: white !important;
	margin-left: 8px !important;
	font-size: 12px !important;
	padding: 8px 24px !important;
	letter-spacing: 0.08em !important;
	border-radius: 24px !important;
	border: 1px solid white !important;
}

@media only screen and (max-width: 960px) {
	.primaryButton {
		font-size: 12px !important;
	}
}

/*RoundButton */

.roundButton {
	min-width: 0 !important;
	height: 60px !important;
	width: 60px !important;
	padding: 0 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	text-align: center !important;
	border-radius: 50% !important;
	background-color: var(--color-main) !important;
	cursor: pointer !important;
	z-index: 50 !important;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 4px rgba(0, 0, 0, 0.12) !important;
}

.roundButton:hover {
	background-color: var(--color-light) !important;
}

.tooltip {
	border-radius: 2 !important;
	font-size: 12px !important;
}
