@import 'styles/vars.css';

/* VerificationFailed */

.verificationFailedRoot {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.verificationFailedContainer {
	max-width: var(--max-width);
	min-width: var(--min-width);
	margin-top: 16px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.verificationFailedContainer div {
	flex: 1;
}

@media only screen and (max-width: 660px) {
	flex-direction: column;
}
