@import 'styles/vars.css';

/* SelectorList */

.selectorListRoot {
	margin: 0;
	display: flex;
	flex-direction: column;
	background-color: white;
	max-width: var(--max-width);
	min-width: var(--min-width);
}

.selectorListStandard {
	width: 95%;
}

.selectorListFull {
	width: 100%;
}

.selectorListSubheader {
	font-weight: 500;
	border-top: 1px solid var(--color-disabled);
	background-color: white;
}

.selectorListTileList {
	width: 100%;
}

.selectorListButtonContainer {
	position: absolute;
	right: 16px;
}

@media only screen and (max-width: 660px) {
	.selectorListButtonContainer {
		right: 8px;
	}
}

/* AddField */

.addFieldRoot {
	margin: 16px 0;
}

/* Tile */

.tileActive {
	font-weight: 700;
	border-left: 8px solid var(--color-main);
	background-color: #e8ecee;
}

.tileContent {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	display: flex;
	align-items: center;
}

.selectListTileNoAvatar {
	margin-left: 16px;
}

.selectListTileName {
	display: flex;
	align-items: center;
	position: relative;
	flex: 1;
}

.headerExpandable {
	display: flex;
	width: 100%;
	flex-direction: 'row';
	align-items: center;
}

.labelExpandable {
	flex: 1;
}
