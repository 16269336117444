@import 'styles/vars.css';

/* Reused */

.headerRoot {
	height: 119px;
	width: 100%;
	background-color: var(--color-background-menu);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-left: 5px;
	padding-right: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.04),
		0px 1px 2px rgba(0, 0, 0, 0.04);
}

.categoryButtonContent {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
}

.categoryIcon {
	margin-right: 8px;
	max-height: 16px;
}

.sectionLayoutContainer {
	width: 200px;
}

.optionMenu {
	width: 100%;
}

/* Timeline Header */

.headerHalf {
	flex: 1;
	display: flex;
}

.imageContainer {
	align-items: center;
	display: flex;
	margin-left: 5%;
	margin-right: 5%;
}

/* EditPostHeader */

.editPostHeaderRoot {
	width: 100%;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 34px;
}

.editPostHeaderContent {
	height: 100%;
	width: 100%;
	max-width: var(--max-width);
	min-width: var(--min-width);
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	padding-bottom: 10px;
	padding-left: 2%;
}

/* DateRange */

.dateButtonContent {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* DateRangePicker */

.dateContainer {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.datePicker {
	width: 90% !important;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}

.clearButtonContainer {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	margin-left: 10px;
}

/* PostExportServiceSwitcher */

.serviceSwitcherMobileRoot {
	max-width: 150px;
	min-width: 150px;
	width: 100%;
}

.serviceSwitcherMobileRoot > button {
	width: 100%;
}

/* SectionLayout */

.sectionLayoutRoot {
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-left: 2%;
	margin-right: 2%;
}

.sectionLayoutRoot * {
	flex: 1;
}

.sectionLayoutLabel {
	display: flex;
	align-items: flex-end;
	padding-left: 5px;
	padding-bottom: 8px;
}

.sectionLayoutContent {
	width: 100%;
	padding-bottom: 8px;
	display: flex;
}

/* ViewSwitcher */

.viewSwitcherRoot {
	display: flex;
	flex-direction: column;
	min-width: 75px;
}

.viewSwitcherButtonRoot {
	width: 100%;
	padding-left: 2px;
}

.viewSwitcherIcon {
	height: 30px;
}

/* MobileHeader */

.mobileHeaderContainer {
	position: relative;
	width: 100%;
	height: 56px;
	display: flex;
	justify-content: flexStart;
	align-items: center;
	background-color: var(--color-main);
	color: white;
}

.navBack {
	color: white !important;
}

.mobileHeaderAvatar {
	background-color: white !important;
	color: var(--color-main) !important;
	margin: 0 16px 0 8px;
}

.mobileHeaderName {
	font-weight: 400 !important;
	font-size: 16px !important;
}

.viewSwitchContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	padding-top: 10px;
}
